<template>
    <div v-if="visible" class="alert-overlay">
        <div class="alert-box">
            <h3 class="alert-title">{{ title }}</h3>
            <p class="alert-message">{{ message }}</p>
            <div class="alert-actions">
                <v-btn variant="outlined" @click="confirm">OK</v-btn>
                <v-btn variant="outlined" v-if="showCancel" @click="cancel">Cancel</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Alert",
        },
        message: {
            type: String,
            required: true,
        },
        showCancel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            visible: true,
        };
    },
    methods: {
        confirm() {
            this.$emit("confirm");
            this.visible = false;
        },
        cancel() {
            this.$emit("cancel");
            this.visible = false;
        },
    },
};
</script>

<style scoped>
.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.alert-box {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.alert-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.alert-message {
    margin-bottom: 20px;
    font-size: 16px;
}

.alert-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.alert-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    /* Make the background transparent for flat style */
    color: black;
    /* Black text for the default state */
    transition: background-color 0.2s ease, color 0.2s ease;
    box-shadow: none;
    /* Remove box shadow to match the flat style */
    text-transform: uppercase;
    /* Capitalize the button text */
    font-weight: bold;
    /* Add bold text to make it more prominent */
}

.alert-button {
    padding: 10px 20px;
    border: 1px solid black;
    /* Add black border */
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    /* Make the background transparent for flat style */
    color: black;
    /* Black text for the default state */
    transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
    box-shadow: none;
    /* Remove box shadow to match the flat style */
    text-transform: uppercase;
    /* Capitalize the button text */
    font-weight: bold;
    /* Add bold text to make it more prominent */
}

.alert-button.cancel {
    background-color: transparent;
    /* Transparent background for the cancel button */
    color: black;
    /* Black text for the cancel button */
    border: 1px solid black;
    /* Black border for the cancel button */
}

.alert-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    /* Light gray background for hover effect */
    color: black;
    /* Keep text black on hover */
    border-color: black;
    /* Maintain the black border on hover */
}

.alert-button.cancel:hover {
    background-color: rgba(0, 0, 0, 0.1);
    /* Light gray background for hover effect */
    color: black;
    /* Keep text black on hover */
    border-color: black;
    /* Maintain the black border on hover */
}
</style>