import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@fortawesome/fontawesome-free/js/all"; // FontAwesome
import "vuetify/styles"; // Vuetify styles
import { createVuetify } from "vuetify"; // Vuetify
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import "bootstrap"; // Bootstrap JS
import authMixin from "./authMixin"; // Import your mixin
import BillingMethodCard from "@/components/BillingMethodCard.vue"; // Import your component
import CustomAlert from "@/components/CustomAlert.vue";
import "./assets/styles.css"; // Or styles.scss

// Initialize Vuetify
const vuetify = createVuetify({
  components,
  directives,
});

// Create the Vue app
const app = createApp(App);

// Register global components
app.component("BillingMethodCard", BillingMethodCard);
app.component("CustomAlert", CustomAlert);

// Use Vue plugins
app.use(router);
app.use(vuetify);

// Add the global mixin
app.mixin(authMixin);

// Global error handling for ChunkLoadError
router.onError((error) => {
  if (error.name === "ChunkLoadError") {
    console.error("ChunkLoadError detected. Reloading the page...");
    window.location.reload();
  }
});

// Mount the app
app.mount("#app");
