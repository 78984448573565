<template>
    <div v-if="!hasPaymentMethod">
        <v-card color="warning" class="mt-4">
            <template v-slot:title>
                <div style="word-wrap: break-word; white-space: normal;">
                    Action Required: Add Payment Method
                </div>
            </template>
            <v-card-text>
                To ensure uninterrupted service, please add a payment method to your account.
            </v-card-text>
            <v-card-actions>
                <v-btn variant="outlined" @click="billing">
                    Go to Billing
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable, getFunctions } from "firebase/functions";

import { db } from "@/firebase";


export default {

    data() {
        return {
            hasPaymentMethod: true,
            stripeCustomerID: null,
            token: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    console.warn("No user is signed in.");
                    return;
                }

                const userRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userRef);

                if (!userDoc.exists()) {
                    console.error(`No document found for user ID: ${user.uid}`);
                    return;
                }

                const userData = userDoc.data();

                // Retrieve access_token
                if (userData?.access_token) {
                    this.token = userData.access_token;
                    console.log("Access token successfully retrieved.");
                } else {
                    console.warn(`Access token not found for user ID: ${user.uid}`);
                }

                if (userDoc.exists()) {
                    this.stripeCustomerID = userDoc.data().stripe_customerID;

                    if (this.stripeCustomerID) {
                        await this.checkPaymentMethod();
                    } else {
                        console.warn("No Stripe customer ID found for the user.");
                    }
                }
            } catch (error) {
                console.error("An error occurred while fetching user data:", error);
            }
        },
        async checkPaymentMethod() {
            try {
                const functions = getFunctions();
                const checkStripePaymentMethod = httpsCallable(functions, "checkStripePaymentMethod");

                const response = await checkStripePaymentMethod({
                    stripeCustomerID: this.stripeCustomerID,
                });

                this.hasPaymentMethod = response.data.hasPaymentMethod;

                if (!this.hasPaymentMethod) {
                    console.warn("No payment method found for this Stripe customer.");
                }
            } catch (error) {
                console.error("Error checking payment method:", error);
            }
        },
        billing() {
            this.$router.push("/billing");
        },
    },
};
</script>



<style scoped></style>